import { readable, writable } from 'svelte/store';

export const time = readable(new Date(), function start(set) {
	const interval = setInterval(() => {
		set(new Date());
	}, 1000);

	return function stop() {
		clearInterval(interval);
	};
});

export const running = writable(localStorage.getItem("running") == 1 ? true : false);
export const schedule = writable(localStorage.getItem("schedule") ? JSON.parse(localStorage.getItem("schedule")) : new Array());
export const starts = writable(null);
export const num = writable(null);
export const len = writable(null);
export const sep = writable(null);

running.subscribe(value => {
	// console.log(value);
	localStorage.setItem("running", value ? 1 : 0);
});
schedule.subscribe(value => {
	// console.log(value, JSON.stringify(value));
	localStorage.setItem("schedule", JSON.stringify(value));
});
