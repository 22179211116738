<script>
	import { running, schedule } from './stores.js';

	const format_clock = new Intl.DateTimeFormat('pt-BR', {
		hour12: false,
		hour: '2-digit',
		minute: '2-digit'
	});

	function close() {
		if ( timer ) clearInterval(timer);
		$running = false;
	}

	let until = '';
	let top = '';
	let center = '';
	let bottom = '';
	let mainclass = '';
	let breaking = false;

	let timer = setInterval(run, 1000);

	function run() {
		let arr = $schedule;

		for (const el of arr) {
			let t = new Date(el.time);
			let n = Date.now();
			if ( Math.ceil(t/1000) < Math.ceil(n/1000)-30 ) {
				top = ( el.label == 'Começar' ? 'Aula' : el.label );
				if ( el.break ) breaking = true; else breaking = false;
				center = 0;
				arr.shift();
				$schedule = arr;
				run();
				break;
			}
			if ( breaking ) {
				until = 'até ' + format_clock.format(t);
			} else {
				until = '';
			}

			bottom = el.label;
			center = Math.ceil( ( t - n ) / ( 60 * 1000 ));
			if ( center <= 0 ) center = 0;
			if ( ! breaking ) {
				if ( center <= 0 ) mainclass = 'bgred blink';
				else if ( center <= 5 ) mainclass = 'bgred';
				else if ( center <= 10 ) mainclass = 'bgorange';
				else if ( center <= 15 ) mainclass = 'bggreen';
				else mainclass = '';
			} else {
				if ( center <= 0 ) mainclass = 'blink';
				else mainclass = '';
			}

			break;
		}

		if ( $schedule.length == 0 ) {
			close();
		}
	}

	function update(val) {
		$schedule[0].time = $schedule[0].time + val * 60 * 1000;
		run();
	}

	run();

</script>

<main class="{mainclass}">
	<div id="top" class:highlight="{until != ''}">{top}<span class="until">{until}</span></div>
	<div id="center">{center} <span>MIN</span></div>
	<div id="bottom">{#if bottom != ''}para <span>{bottom}</span>{/if}</div>
	<section>
		<button on:click={() => update(-1)}>–</button>
		<button on:click={close}>FECHAR</button>
		<button on:click={() => update(+1)}>+</button>
	</section>
</main>

<style>
	#center {
		font-size: 15rem;
		font-weight: bold;
		line-height: 1;
	}
	#center span {
		font-size: 0.8em;
	}
	#top, #bottom {
		font-size: 6rem;
		color: rgba(255,255,255,0.4);
		line-height: 1;
	}
	#top {
		text-transform: uppercase;
		font-weight: bold;
	}
  section {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-top: 5rem;
	}
	.until {
		text-transform: none;
		display: block;
	}
	.highlight {
		color: yellow !important;
	}
	.blink > #center {
		animation: blink 3s ease-in infinite;
	}
	@keyframes blink{
		0%{opacity: 0;}
		30%{opacity: 1;}
		70%{opacity: 1;}
		100%{opacity: 0;}
	}

	.bggray, .bgred, .bgorange, .bggreen {
		background-color: #000000;
		-webkit-transition: all 1.0s ease;
		-moz-transition: all 1.0s ease;
		-o-transition: all 1.0s ease;
		-ms-transition: all 1.0s ease;
		transition: all 1.0s ease;
	}
	.bggray {
		background-color: #444444;
	}
	.bgred {
		background-color: #990000;
	}
	.bgorange {
		background-color: #CC6600;
	}
	.bggreen {
		background-color: #006600;
	}
	.bgwhite {
		background-color: #FFFFFF;
	}
</style>
