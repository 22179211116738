<script>
	import { starts, len, num } from './stores.js';
	export let showConfig;

	let dialog;
	$: if (dialog && showConfig) {
		dialog.showModal();
	}

	let custom;
	let h = new Date().getHours();
	let m = new Date().getMinutes();

	let hour = h;
	let min;

	// console.log($len, min);
	$: if ( $len ) {
		if ( m < 15 ) min = 15;
		else if ( m < 30 ) min = 30;
		else if ( m < 45 ) min = 45;
		else {
			min = 0;
			hour = new Date(Date.now() + 60 * 60 * 1000).getHours();
		}
	}

	let hours = [];
	for (let i = 0; i < 24; i++) {
		hours.push(i);
	}

	let mins = [];
	for (let i = 0; i < 60; i=i+5) {
		mins.push(i);
	}

	function confirm() {
		let s = new Date();
		if ( $num && $num > 0 ) {
			s.setHours(hour);
			s.setMinutes(min);
			s.setSeconds(0);
			if ( s.getTime() < Date.now() - ( $len * ($num+1) ) * 60 * 60 * 1000 ) {
				s.setTime( s.getTime() + 24 * 60 * 60 * 1000 );
			}
			$starts = s;
		} else if ( parseInt(custom,10) ) {
			$starts = s;
			$len = parseInt(custom,10);
		}
		dialog.close();
	}

	function cancel() {
		$starts = null;
		dialog.close();
	}

	function autofocus(node) {
		node.focus();
	}

</script>

<dialog bind:this={dialog} on:close={() => (showConfig = false)} on:click|self={cancel}>
	<main on:click|stopPropagation>
		<h2>{#if $len}INÍCIO{:else}TEMPO{/if}</h2>
		<form class:hidden="{$len == null}">
			<select bind:value={hour}>
				{#each hours as i}
				<option value="{i}">{i.toString().padStart(2,'0')}</option>
				{/each}
			</select>
			<div>:</div>
			<select bind:value={min}>
				{#each mins as i}
				<option value="{i}" >{i.toString().padStart(2,'0')}</option>
				{/each}
			</select>
		</form>
		<form class:hidden="{$len != null}">
			<input type="text" inputmode="numeric" pattern="[0-9]*" bind:value={custom} use:autofocus /> min
		</form>
		<section>
			<button class="primary" on:click={confirm}>OK</button>
			<button on:click={cancel}>Cancelar</button>
		</section>
	</main>
</dialog>

<style>

	dialog {
		width: 100%;
		height: 100%;
		background: #CCC;
		font-size: 1.5em;
	}
	h2 {
		margin: 0;
		padding: 0;
		font-size: 4rem;
	}
	form, section {
		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
	}
	form {
		font-size: 4rem;
	}
	section {
		font-size: 1.5rem;
		margin-top: 1rem;
	}
	select, input {
		margin: 1rem;
	}
	button {
		margin: 0.5rem;
		font-size: 3rem;
	}
	input {
		width: 10rem;
		text-align: right;
	}
	.primary {
		background: #000;
		border-color: #000;
	}

</style>