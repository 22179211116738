<script>
	import { running, starts, num, len, sep, schedule } from './stores.js';
	import Config from './Config.svelte';
	let showConfig = false;

	function timer() {
		$running = false;
		$schedule = new Array();
		$num = parseInt(this.dataset.num,10) || null;
		$len = parseInt(this.dataset.len,10) || null;
		$sep = parseInt(this.dataset.sep,10) || null;
		// console.log($num, $len, $sep);
		showConfig = true;
	}

	$: if ( ! $running && $starts != null && $len > 0 ) execute();

	function execute() {
		// console.log($starts, $num, $len, $sep);

		let n = Date.now();
		let s = $starts.getTime();

		let arr = new Array();
		let first = $num > 0 || s / 1000 > n / 1000 ? 'Começar' : '';
		let last = $num > 0 ? 'Encerrar' : '';

		if ( first != '' ) {
			arr.push({ time: new Date(s).getTime(), label: first, break: false });
		}

		for ( let i=1; i < $num; i++ ) {
			s += $len * 60*1000;
			arr.push({ time: new Date(s).getTime(), label: 'Intervalo', break: true });

			s += $sep * 60*1000;
			arr.push({ time: new Date(s).getTime(), label: 'Aula', break: false });
		}

		s += $len * 60*1000;
		arr.push({ time: new Date(s).getTime(), label: last, break: false });

		// console.log(arr);
		schedule.set(arr);
		running.set(true);
	}

</script>

<section>
	<button on:click={timer} data-num="1" data-len="60">1 AULA<span>60 minutos</span></button>
	<button on:click={timer} data-num="3" data-len="60" data-sep="15">3 AULAS<span>15 min intervalo</span></button>
	<button on:click={timer}>TEMPO<span>customizado</span></button>
</section>

<Config bind:showConfig />

<style>
section {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 5rem;
}
</style>
