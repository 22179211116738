<script>
	import { running, starts, schedule } from './stores.js';
	import Clock from './Clock.svelte';
	import Timer from './Timer.svelte';

	export function reset() {
		// console.log('RESET');
		$starts = null;
		$schedule = new Array();
	}

	$: if ( ! $running ) reset();
</script>

<main>
	{#if $running}
	<Timer/>
	{:else}
	<Clock/>
	{/if}
</main>

<style>
</style>
