<script>
	import Buttons from './Buttons.svelte';
	import { time } from './stores.js';

	const format_clock = new Intl.DateTimeFormat('pt-BR', {
		hour12: false,
		hour: '2-digit',
		minute: '2-digit'
	});
	const format_weekday = new Intl.DateTimeFormat('pt-BR', {
		weekday: "short"
	});
	const format_day = new Intl.DateTimeFormat('pt-BR', {
		day: "2-digit"
	});
	const format_month = new Intl.DateTimeFormat('pt-BR', {
		month: "short"
	});

	const format_date = (time) => {
		return format_weekday.format(time).replace(".","") + ' ' + format_day.format(time) + '/' + format_month.format(time).replace(".","");
	}
	const format_time = (time) => {
		return format_clock.format(time);
	}

</script>

<main>
	<div id="top" class:hidden="{$time.getHours() % 2 != 0}">{format_date($time)}</div>
	<div id="center">{format_time($time)}</div>
	<div id="bottom" class:hidden="{$time.getHours() % 2 == 0}">{format_date($time)}</div>
	<Buttons/>
</main>

<style>
#center {
	font-size: 16rem;
	font-weight: bold;
	line-height: 1;
}
#top, #bottom {
	font-size: 6rem;
	color: rgba(255,255,255,0.5);
	text-transform: uppercase;
	line-height: 1;
}
</style>
